import * as React from 'react';
import {PaperProps, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import moment from 'moment'
import CircularProgress from "@mui/material/CircularProgress";
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import {styled} from "@mui/material/styles";

export const WynyardStation: string = "2000406";
export const CentralStation: string = "200060";
export const AshfieldStation: string = "213110";

const WalkTimeMinutes: number = 12;
const MinutesPast: number = 5;
const NumberToShow: number = 10;

interface TrainResponse {
    stopEvents: StopEvent[];
}

interface StopEvent {
    departureTimeEstimated: string;
    departureTimePlanned: string;
    isRealtimeControlled: boolean;
    onwardLocations: OnwardLocation[];
    location: Location;
}

interface Location {
    name: string;
}

interface OnwardLocation {
    arrivalTimeEstimated: string;
    arrivalTimePlanned: string;
    departureTimeEstimated: string;
    departureTimePlanned: string;
    name: string;
    parent: OnwardLocationParent;
}

interface OnwardLocationParent {
    id: string;
}

const StyledPaperCanMake = styled(Paper)<PaperProps>(({ theme }) => ({
    backgroundColor: green[200],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: '5px 0px',
}));

const StyledPaperNeedToLeave = styled(Paper)<PaperProps>(({ theme }) => ({
    backgroundColor: orange[200],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: '5px 0px',
}));

const StyledPaperMissed = styled(Paper)<PaperProps>(({ theme }) => ({
    backgroundColor: red[200],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: '5px 0px',
}));


type State = {
    isLoaded: boolean;
    time: string;
    response: TrainResponse | null;
    from: string;
    to: string;
    showNextStop: boolean;
};

export interface Props {
    from: string;
    to: string;
    showNextStop: boolean;
}

export default class TrainTimetable extends React.Component<Props, State> {
    state = {
        isLoaded: false,
        time: new Date().toISOString(),
        response: null,
        from: this.props.from,
        to: this.props.to,
        showNextStop: this.props.showNextStop
    };
    componentDidMount() {
        setInterval( () => {
            this.setState({
                ...this.state,
                time : new Date().toISOString()
            })
        },1000 * 10)

        fetch("https://apps.mlai.org/transportnsw.php?to=" + this.state.to + "&from=" + this.state.from)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ...this.state,
                        isLoaded: true,
                        response: result
                    })
                },
                (error) => {
                    this.setState({
                        ...this.state,
                        isLoaded: true,
                        response: null
                    })
                }
            )
    }

    renderCards() {
        if (this.state.response == null || !this.state.response.stopEvents) {
            return null;
        }
        // console.log(this.state.response.stopEvents);
        const allTrains = this.state.response.stopEvents
            .filter((se: StopEvent) => moment(se.departureTimePlanned).add(-1 * WalkTimeMinutes + MinutesPast, 'minutes').isAfter(this.state.time))
            .slice(0, NumberToShow)
            .map((se: StopEvent, index: number) => {
                const departureTime = moment(se.departureTimePlanned).utc().local().format("hh:mm:ss a");
                const departingInMinutes = moment(se.departureTimePlanned).diff(this.state.time, "minutes");
                const leaveHomeIn = (this.props.showNextStop ? "Leave " : "Leave ") + moment(se.departureTimePlanned).add(-1 * WalkTimeMinutes, 'minutes').from(this.state.time);
                let secondaryStop = "First stop: " + (se.onwardLocations.length > 0 ? se.onwardLocations[0].name : "");
                if (!this.props.showNextStop) {
                    const destinationIndex = se.onwardLocations.findIndex((value: OnwardLocation) => value.parent.id === this.props.to);
                    secondaryStop = "Also stops at: "
                        + (destinationIndex > 0
                            ? se.onwardLocations[destinationIndex - 1].name
                            : "");
                }

                const content = (
                    <>
                        <Typography component="p">
                            Departure time {departureTime}
                        </Typography>
                        <Typography variant="h5" component="h5">
                            {leaveHomeIn}
                        </Typography>
                        <Typography component="p">
                            {se.location.name}
                        </Typography>
                        <Typography component="p">
                            {secondaryStop}
                        </Typography>

                    </>
                )

                if (departingInMinutes <= 10) {
                    return (
                        <StyledPaperMissed elevation={1} key={index}>
                            {content}
                        </StyledPaperMissed>
                    )
                } else if (departingInMinutes <= 20) {
                    return (
                        <StyledPaperNeedToLeave elevation={1} key={index}>
                            {content}
                        </StyledPaperNeedToLeave>
                    )
                } else {
                    return (
                        <StyledPaperCanMake elevation={1} key={index}>
                            {content}
                        </StyledPaperCanMake>
                    )
                }
        });

        return allTrains;
    }

    render() {
        if (!this.state.isLoaded) {
            return <CircularProgress/>;
        }
        return (
            this.renderCards()
        );
    }
}