import * as React from 'react';
import Container, {ContainerProps} from '@mui/material/Container';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {Card, CardContentProps, CardProps, GridProps} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Clock from "../components/Clock";
import TrainTimetable, {AshfieldStation, CentralStation} from "../components/TrainTimetable";
import Weather, {Ashfield, Coogee, MillersPoint} from "../components/Weather";
import { styled } from '@mui/material/styles';
import Pollution from "../components/Pollution";

const StyledScrollContainer = styled(Grid)<GridProps>(({ theme }) => ({
    width: 'auto',
    overflowX: 'scroll',
    overflowY: 'hidden',
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
}));

const StyledContent = styled(Grid)<GridProps>(({ theme }) => ({
    width: 'max-content',
}));

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
    width: 330,
}));

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
    minWidth: 275,
    margin: '5px',
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
    padding: '10px 0px',
}));

const StyledRoot = styled(Container)<ContainerProps>(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(2),
}));

const StyledScrollable = styled(Container)<ContainerProps>(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxHeight: '80vh',
        overflow: 'auto',
        '-ms-overflow-style': 'none',  /* IE and Edge */
        scrollbarWidth: 'none',  /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    paddingLeft: 10,
    paddingRight: 10
}));

const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: 14,
}));

export default function App() {
    return (
        <StyledRoot maxWidth={false} disableGutters={true}>
            <Typography variant="h4" gutterBottom>
                <Clock/>
            </Typography>
            <StyledScrollContainer>
                <StyledContent container>
                    <StyledGrid item>
                        <StyledCard>
                            <StyledCardContent>
                                <StyledTitle color="textSecondary" gutterBottom>
                                    To Central
                                </StyledTitle>
                                <StyledScrollable disableGutters={true}>
                                    <TrainTimetable from={AshfieldStation} to={CentralStation} showNextStop/>
                                </StyledScrollable>
                            </StyledCardContent>
                        </StyledCard>
                    </StyledGrid>
                    <StyledGrid item>
                        <StyledCard>
                            <StyledCardContent>
                                <StyledTitle color="textSecondary" gutterBottom>
                                    Ashfield Weather
                                </StyledTitle>
                                <StyledScrollable disableGutters={true}>
                                    <Weather at={Ashfield}/>
                                </StyledScrollable>
                            </StyledCardContent>
                        </StyledCard>
                    </StyledGrid>
                    <StyledGrid item>
                        <StyledCard>
                            <StyledCardContent>
                                <StyledTitle color="textSecondary" gutterBottom>
                                    Millers Point Weather
                                </StyledTitle>
                                <StyledScrollable disableGutters={true}>
                                    <Weather at={MillersPoint}/>
                                </StyledScrollable>
                            </StyledCardContent>
                        </StyledCard>
                    </StyledGrid>
                    <StyledGrid item>
                        <StyledCard>
                            <StyledCardContent>
                                <StyledTitle color="textSecondary" gutterBottom>
                                    Coogee Weather
                                </StyledTitle>
                                <StyledScrollable disableGutters={true}>
                                    <Weather at={Coogee}/>
                                </StyledScrollable>
                            </StyledCardContent>
                        </StyledCard>
                    </StyledGrid>
                    <StyledGrid item>
                        <StyledCard>
                            <StyledCardContent>
                                <StyledTitle color="textSecondary" gutterBottom>
                                    Coogee Beach Pollution
                                </StyledTitle>
                                <StyledScrollable disableGutters={true}>
                                    <Pollution />
                                </StyledScrollable>
                            </StyledCardContent>
                        </StyledCard>
                    </StyledGrid>
                </StyledContent>
            </StyledScrollContainer>
        </StyledRoot>

        // <Container maxWidth="sm">
        //     <Box sx={{ my: 4 }}>
        //         <Typography variant="h4" component="h1" gutterBottom>
        //             Material UI Create React App example in TypeScript
        //         </Typography>
        //         {/*<ProTip />*/}
        //         <Copyright />
        //     </Box>
        // </Container>
    );
}
















//
//
//
//
// import * as React from 'react';
// import Typography from '@mui/material/Typography';
// import {createStyled, createMuiTheme, Theme} from '@mui/material/styles';
// import createStyles from '@mui/material/styles/createStyles';
// import withStyles, {WithStyles} from '@mui/material/styles/withStyles';
// import withRoot from '../withRoot';
// import {Card} from "@mui/material";
// import CardContent from "@mui/material/CardContent";
// import Grid from "@mui/material/Grid";
// import Clock from "../components/Clock";
// import TrainTimetable, {AshfieldStation, CentralStation} from "../components/TrainTimetable";
// import Weather, {Ashfield, MillersPoint} from "../components/Weather";
// import {DefaultTheme} from "@mui/private-theming/defaultTheme";
//
// const styles = (theme: Theme) =>
//   createStyles({
//     root: {
//       textAlign: 'center',
//       paddingTop: theme.spacing(2),
//     },
//       card: {
//           minWidth: 275
//       },
//       cardContent: {
//           padding: '10px',
//       },
//       scrollable: {
//           [theme.breakpoints.up('md')]: {
//               maxHeight: '80vh',
//               overflow: 'auto',
//               '-ms-overflow-style': 'none',  /* IE and Edge */
//               scrollbarWidth: 'none',  /* Firefox */
//               '&::-webkit-scrollbar': {
//                   display: 'none',
//               }
//           },
//       },
//       bullet: {
//           display: 'inline-block',
//           margin: '0 2px',
//           transform: 'scale(0.8)',
//       },
//       title: {
//           fontSize: 14,
//       },
//       pos: {
//           marginBottom: 12,
//       },
//
//   });
//
// type State = {
//   open: boolean;
// };
//
// class Index extends React.Component<WithStyles<typeof styles>, State> {
//   state = {
//     open: false
//   };
//
//   handleClose = () => {
//     this.setState({
//       open: false,
//     });
//   };
//
//   handleClick = () => {
//     this.setState({
//       open: true,
//     });
//   };
//
//   render() {
//     return (
//
//     );
//   }
// }
//
// export default withRoot(createStyled(styles)(Index));
