import * as React from 'react';
import {PaperProps, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material/styles";
import VideoPlayer from "./VideoPlayer";
import Paper from "@mui/material/Paper";

interface PollutionResponse {
    PollForecastDt: string;
    PollutionDescription: string;
    WaterTemp: number;
    SiteName: string;
    PollutionForecast: string;
}

interface StyledPaperProps extends PaperProps {
    colour?: string;
}

const StyledTempContainer = styled(Paper)<StyledPaperProps>(({ colour, theme }) => ({
    backgroundColor: colour,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
}));

const cameraStreamUrl = "https://randwickcc-hls.secdn.net/randwickcc-channel/sevu/coogee_beach.smil/playlist.m3u8";

const styleLikely = {
    icon: 'https://www.beachwatch.nsw.gov.au/assets/images/WaterqualityForecastIndicator/Red.svg',
    background: 'rgb(255, 230, 234)'
}
const stylePossible = {
    icon: 'https://www.beachwatch.nsw.gov.au/assets/images/WaterqualityForecastIndicator/Yellow.svg',
    background: 'rgb(253, 231, 154)'
}
const styleUnlikely = {
    icon: 'https://www.beachwatch.nsw.gov.au/assets/images/WaterqualityForecastIndicator/Green.svg',
    background: 'rgb(219, 250, 223)'
}

type State = {
    isLoaded: boolean;
    time: string;
    response: PollutionResponse | null;
    key: string;
    pass: string;
};

export interface Props {
}

export default class Pollution extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            time: new Date().toISOString(),
            response: null,
            key: null,
            pass: null,
        };
    }


    componentDidMount() {
        setInterval( () => {
            this.setState({
                time : new Date().toISOString()
            })
        },1000 * 60 * 15);

        fetch("https://apps.mlai.org/beachwatch.php")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        response: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        response: null
                    });
                }
            );

        fetch("https://apps.mlai.org/coogeecam.php")
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.length === 3) {
                        this.setState({
                            key: result[1],
                            pass: result[2]
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );



    }
    renderPollution() {
        if (this.state.response == null || !this.state.response.PollForecastDt) {
            return null;
        }

        return this.wrapPollution(
            <React.Fragment>
                <Typography variant="h5" component="h5">
                    <img src={this.getPollutionStyle(this.state.response.PollutionForecast).icon} alt={this.state.response.PollutionForecast} /> Pollution {this.state.response.PollutionForecast}
                </Typography>
                <Typography component="p">
                    {this.state.response.PollutionDescription}
                </Typography>
                <Typography component="p">
                    Water temperature {this.state.response.WaterTemp + "°"}
                </Typography>
                <Typography component="p">
                    Last updated at {this.getLocalDateTime(this.state.response.PollForecastDt)}
                </Typography>
            </React.Fragment>
            , this.state.response.PollutionForecast);
    }

    getPollutionStyle(forecast) {
        return forecast === 'Unlikely' ? styleUnlikely : forecast === 'Possible' ? stylePossible : styleLikely;
    }

    getLocalDateTime(utc) {
        var date = new Date(utc);
        var hours = date.getHours();
        var amOrPm = hours >= 12 ? 'pm' : 'am';
        hours = (hours % 12) || 12;

        ///${date.getDate()}/${date.getMonth() + 1}
        return `${this.dayOfWeekAsString(date.getDay())} ${hours}:${date.getMinutes()} ${amOrPm}`;
    }

    dayOfWeekAsString(dayIndex) {
        return ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][dayIndex] || '';
    }

    wrapPollution(contents, forecast) {
        return (
            <StyledTempContainer colour={this.getPollutionStyle(forecast).background}>
                {contents}
            </StyledTempContainer>
        );
    }



    //Not sure why, but the double == allows it to work...
    getVideoJsOptions() {
        return {
            sources: [
                {
                    src: cameraStreamUrl + "?key==" + this.state.key + "&pass=" + this.state.pass,
                    type: "application/x-mpegURL"
                }
            ]
        };
    }

    renderCamera() {
        if (!this.state.key || !this.state.pass) {
            return null;
        }
        return (
            <VideoPlayer options={this.getVideoJsOptions()} />
        );
    }

    render() {
        if (!this.state.isLoaded && !this.state.response) {
            return <CircularProgress/>;
        }

        return (
            <div>
                {this.renderPollution()}
                {this.renderCamera()}
            </div>
        );
    }
}
