import * as React from 'react';
import {PaperProps, TableCellProps, TableProps, Typography} from "@mui/material";
import moment from 'moment'
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import orange from '@mui/material/colors/orange';
import {styled} from "@mui/material/styles";
import common from "@mui/material/colors/common";
import Paper from "@mui/material/Paper";

export const Ashfield: string = "r3grny";
export const MillersPoint: string = "r3gx2s";

export const Coogee: string = "r3gx1b";

interface WeatherResponse {
    data: Data[];
}

interface WeatherDailyResponse {
    data: DailyData[];
}

interface WeatherObservationResponse {
    data: Data;
}

interface Data {
    rain: DataRain;
    temp: number;
    temp_feels_like: number;
    humidity: number;
    wind: DataWind;
    time: string;
}

interface DailyData {
    rain: DataRain;
    temp_min: number;
    temp_max: number;
    date: string;
    short_text: string;
}

interface DataRain {
    amount: DataRainAmount;
    chance: number;
}
interface DataRainAmount {
    min: number | null;
    max: number | null;
    units: string;
}

interface DataWind {
    speed_kilometre: number;
}

interface StyledTableCellProps extends TableCellProps {
    colour?: string;
}

interface StyledPaperProps extends PaperProps {
    colour?: string;
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(({ colour, theme }) => ({
    backgroundColor: colour,
    padding: '4px'
}));

const StyledTempContainer = styled(Paper)<StyledPaperProps>(({ colour, theme }) => ({
    backgroundColor: colour,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

const StyledTable = styled(Table)<TableProps>(({   theme }) => ({
    minWidth: 100,
    margin: '10px 0',
}));

const blank = common.white;
const freezing = blue[100];
const cold = blue[200];
const warm = orange[200];
const hot = red[200];

type State = {
    isLoaded: boolean;
    time: string;
    responseHourly: WeatherResponse | null;
    responseDaily: WeatherDailyResponse | null;
    responseObservation: WeatherObservationResponse | null;
    at: string;
};

export interface Props {
    at: string;
}

export default class Weather extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            time: new Date().toISOString(),
            responseHourly: null,
            responseDaily: null,
            responseObservation: null,
            at: this.props.at
        };
    }


    componentDidMount() {
        setInterval( () => {
            this.setState({
                time : new Date().toISOString()
            })
        },1000 * 60 * 15);

        fetch("https://api.weather.bom.gov.au/v1/locations/" + this.state.at + "/forecasts/hourly")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        responseHourly: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        responseHourly: null
                    });
                }
            );

        fetch("https://api.weather.bom.gov.au/v1/locations/" + this.state.at + "/forecasts/daily")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        responseDaily: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        responseDaily: null
                    });
                }
            );

        fetch("https://api.weather.bom.gov.au/v1/locations/" + this.state.at + "/observations")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        responseObservation: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        responseObservation: null
                    });
                }
            )
    }

    renderHourlyForecast() {
        if (this.state.responseHourly == null || !this.state.responseHourly.data) {
            return null;
        }
        const allData = this.state.responseHourly.data
            .slice(0, 12)
            .map((d: Data, index: number) => {
                const hour = moment(d.time).utc().local().format("h a");

                return (
                    <TableRow key={index}>
                        <StyledTableCell component="th" scope="row" colour={blank}>
                            {hour}
                        </StyledTableCell>
                        <StyledTableCell align="right" colour={this.getTempClassName(d.temp)}>{d.temp + "°"}</StyledTableCell>
                        <StyledTableCell align="right" colour={this.getRainChanceClassName(d.rain.chance)}>{d.rain.chance + "%"}</StyledTableCell>
                        <StyledTableCell align="right" colour={this.getRainAmountClassName(d.rain.amount.min)}>{(d.rain.amount.min == null ? "" : d.rain.amount.min) + (d.rain.amount.max == null ? "" : "-" + d.rain.amount.max) + " " + d.rain.amount.units}</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>{d.wind.speed_kilometre + "km/h"}</StyledTableCell>
                    </TableRow>
                );
        });

        return (
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell colour={blank}>🕒</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>☀️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>☂️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>💦️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>🍃</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allData}
                </TableBody>
            </StyledTable>
        );
    }

    renderDailyForcast() {
        if (this.state.responseDaily == null || !this.state.responseDaily.data) {
            return null;
        }
        const allData = this.state.responseDaily.data
            .slice(1, 6)
            .map((d: DailyData, index: number) => {
                const day = moment(d.date).utc().local().format("dddd").substring(0,3);

                return (
                    <TableRow key={index}>
                        <StyledTableCell component="th" scope="row" colour={blank}>
                            {day}
                        </StyledTableCell>
                        <StyledTableCell align="right" colour={this.getTempClassName(d.temp_min)}>{d.temp_min ? d.temp_min + "°" : '-'}</StyledTableCell>
                        <StyledTableCell align="right" colour={this.getTempClassName(d.temp_max)}>{d.temp_max + "°"}</StyledTableCell>
                        <StyledTableCell align="right" colour={this.getRainChanceClassName(d.rain.chance)}>{d.rain.chance + "%"}</StyledTableCell>
                        <StyledTableCell align="right" colour={this.getRainAmountClassName(d.rain.amount.min)}>{(d.rain.amount.min == null ? "" : d.rain.amount.min) + (d.rain.amount.max == null ? "" : "-" + d.rain.amount.max) + " " + d.rain.amount.units}</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>{d.short_text}</StyledTableCell>
                    </TableRow>
                );
            });

        return (
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell colour={blank}>🕒</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>❄️️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>☀️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>☂️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}>💦️</StyledTableCell>
                        <StyledTableCell align="right" colour={blank}></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allData}
                </TableBody>
            </StyledTable>
        );
    }

    renderObservation() {
        if (this.state.responseObservation == null || !this.state.responseObservation.data) {
            return null;
        }

        return this.wrapTemp(
            <React.Fragment>
                <Typography variant="h5" component="h5">
                    Temperature {this.state.responseObservation.data.temp + "°"}
                </Typography>
                <Typography component="p">
                    Feels like {this.state.responseObservation.data.temp_feels_like + "°"}
                </Typography>
                <Typography component="p">
                    Humidity {this.state.responseObservation.data.humidity + "%"}
                </Typography>
                <Typography component="p">
                    Wind {this.state.responseObservation.data.wind.speed_kilometre + "km/h"}
                </Typography>
            </React.Fragment>
        , this.state.responseObservation.data.temp);
    }

    getTempClassName(temp) {
        return temp <= 16 ? freezing : temp <= 20 ? cold : temp <= 28 ? warm : hot;
    }

    getRainChanceClassName(percent) {
        return percent >= 10 ? freezing : blank;
    }

    getRainAmountClassName(mm) {
        return mm >= 2 ? freezing : blank;
    }

    wrapTemp(contents, temp) {
        return (
            <StyledTempContainer colour={this.getTempClassName(temp)}>
                {contents}
            </StyledTempContainer>
        );
    }

    render() {
        if (!this.state.isLoaded && !this.state.responseObservation && !this.state.responseHourly && !this.state.responseDaily) {
            return <CircularProgress/>;
        }

        return (
            <div>
                {this.renderObservation()}
                {this.renderHourlyForecast()}
                {this.renderDailyForcast()}
            </div>
        );
    }
}
