import * as React from 'react';
import Moment from "react-moment";

type State = {
    time: string;
}
interface Props {

}

export default class Clock extends React.Component<Props, State> {
    state = {
        time: new Date().toISOString(),
    };

    componentDidMount() {
        setInterval( () => {
            this.setState({
                time : new Date().toISOString()
            })
        },1000)
    }

    render() {
        return (
            <Moment format="dddd Do MMMM YYYY - hh:mm:ss a">
                {this.state.time}
            </Moment>
        );
    }
}